import React, { Component } from 'react'
import CommonButtons from '../common-buttons/CommonButtons';
import './AppServices.scss';

export default class AppServices extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    btnClicked(e: any) {
        console.log(e);
        let ctrlName = e.target.name;
        let location = window.location.pathname.toString().substring(1);
        switch (ctrlName) {
            case 'kt':
                window.location.href = '/contactus?help=kt&src=' + location;
                break;
        }
    }
    render() {
        return (
            <section id="services" className="services">
                <div className="services-header-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="services-header">
                                <div className='left-section'>
                                    <div className='header-text'><span className='text-purple-gradient'>Experience Innovation</span></div>
                                    <p className='header-description'>We specialize in experience design and digital engagement to maximize the lifetime value of your customers by nurturing brand loyalty.</p>
                                    <CommonButtons></CommonButtons>
                                </div>
                                <img src="assets/img/all/services-header-artwork.svg" className='web-art' alt="About" />
                                <img src="assets/img/all/services-header-artwork-sm.svg" className='mobile-art' alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-section-one-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-one'>
                            <div className='header'>
                                <div>Innovative <span className='title'>Solutions.</span></div>
                                <div>Proven <span className='title'>Methodology.</span></div>
                                <div>Delivered <span className='title'>Results.</span></div>
                            </div>
                            <p>We apply artificial intelligence and behavioural science principles to deliver a smarter, kinder future for customer experience.</p>
                            <button name='kt' className='schedule-kt-session-btn' onClick={this.btnClicked}>Schedule a knowledge sharing session</button>
                        </div>
                    </div>
                </div>
                <div className="services-section-two-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-two'>
                            <div className='header'>
                                <div>Reimagined <span className='text-highlighter-green'>Experiences</span></div>
                            </div>
                            <div className='content'>
                                <div className='item purple-box'>
                                    <div className='left-section'>Power sales growth and extend market reach</div>
                                    <div className='right-section'>Imagine doubling or tripling the capacity of your sales
                                        and marketing teams with artificial intelligence and process automation while also
                                        accelerating the sales process from prospecting to qualification to closing.</div>
                                </div>
                                <div className='item orange-box'>
                                    <div className='left-section'>Nurture relationships for customer retention</div>
                                    <div className='right-section'>Imagine a unified brand experience that adapts to the
                                        individual and to his/her/their specific journey moment while remembering them from one
                                        interaction to the next. Proactive and seamless to foster loyalty and build customer advocacy</div>
                                </div>
                                <div className='item blue-box'>
                                    <div className='left-section'>Design the future of service operations</div>
                                    <div className='right-section'>Imagine your Contact Center as a Service (CCaaS)
                                        that applies voice recognition, artificial intelligence and automation principles
                                        to deliver improved engagement, experience, and efficiency.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-section-three-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-three'>
                            <div className='header'>
                                <div><span className='text-highlighter-green'>Design</span> Thinking & <span className='text-highlighter-green'>Operations</span> Consulting</div>
                            </div>
                            <p className='header-description'>Our discovery process meets your organization where it is today, analyzing the mechanics, and envisioning the path to digital transformation, one step at a time.</p>
                            <div className='content'>
                                <div className='item'>
                                    <div className='numbering'>1</div>
                                    <div className='text'><span className='text-purple-gradient'>As-is Analysis:</span> Map the current state - stakeholders, successes and challenges</div>
                                    <img className='artwork' src='assets/img/all/services-section-3-artwork-1.svg' alt='step 1' />
                                </div>
                                <div className='item'>
                                    <div className='numbering'><span>2</span></div>
                                    <div className='text'><span className='text-purple-gradient'>To-Be Vision:</span> Define the strategy and priorities for business and IT stakeholders</div>
                                    <img className='artwork' src='assets/img/all/services-section-3-artwork-2.svg' alt='step 2' />
                                </div>
                                <div className='item'>
                                    <div className='numbering'>3</div>
                                    <div className='text'><span className='text-purple-gradient'>Gap Analysis:</span> Identify opportunities for operations, AI and automation</div>
                                    <img className='artwork' src='assets/img/all/services-section-3-artwork-3.svg' alt='step 3' />
                                </div>
                                <div className='item'>
                                    <div className='numbering'>4</div>
                                    <div className='text'><span className='text-purple-gradient'>Roadmap:</span> Plan the vision, align with financial targets and innovation goals</div>
                                    <img className='artwork' src='assets/img/all/services-section-3-artwork-4.svg' alt='step 4' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services-section-four-container1">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-four'>
                            <div className='header'>
                                <div><span className='text-highlighter-green'>Agile Configuration</span> & Implementation</div>
                            </div>
                            <p className='header-description'>Our approach is centered around rapid prototyping, deployment and iterative implementation, understanding and bridging the people, processes, and technologies.</p>
                            <div className='content'>
                                <div className='item purple-bg'>
                                    <div className='text'><span className='title'>Experience Design and Configuration:</span> Design, develop and configure interactive and proactive engagement to
                                        deliver an omni-channel experience</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-1.svg' alt='step 1' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-1.svg' alt='step 1' />
                                    <img className='arrow1' src='assets/img/all/downArrowRight.svg' alt='step 1' />
                                </div>
                                <div className='item orange-bg'>
                                    <div className='text'><span className='title'>Systems Integration:</span> Integrate seamlessly with CRMs, transactional systems, data sources and APIs</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-3.svg' alt='step 3' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-2.svg' alt='step 3' />
                                    <img className='arrow1' src='assets/img/all/downArrowLeft.svg' alt='step 1' />
                                </div>
                                <div className='item blue-bg'>
                                    <div className='text'><span className='title'>Management & Reporting:</span> Monitor and review live experience to derive key insights to optimize experience</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-4.svg' alt='step 4' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-3.svg' alt='step 4' />
                                    <img className='arrow1' src='assets/img/all/downArrowRight.svg' alt='step 1' />
                                </div>
                                <div className='item green-bg'>
                                    <div className='text'><span className='title'>Train & Test Experience:</span> Preview the experience, fine tune training, conduct rigorous testing and launch to source user data</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-2.svg' alt='step 2' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-4.svg' alt='step 2' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-section-four-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-four'>
                            <div className='header'>
                                <div><span className='text-highlighter-green'>Agile Configuration</span> & Implementation</div>
                            </div>
                            <p className='header-description'>Our approach is centered around rapid prototyping, deployment and iterative implementation, understanding and bridging the people, processes, and technologies.</p>
                            <div className='content'>
                                <div className='item purple-bg'>
                                    <div className='text'><span className='title'>Experience Design and Configuration:</span> Design, develop and configure interactive and proactive engagement to
                                        deliver an omni-channel experience</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-1.svg' alt='step 1' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-1.svg' alt='step 1' />
                                    <img className='arrow1' src='assets/img/all/downArrowRight.svg' alt='step 1' />
                                </div>
                                <div className='item green-bg'>
                                    <div className='text'><span className='title'>Train & Test Experience:</span> Preview the experience, fine tune training, conduct rigorous testing and launch to source user data</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-2.svg' alt='step 2' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-4.svg' alt='step 2' />
                                    <img className='arrow1' src='assets/img/all/downArrowLeft.svg' alt='step 1' />
                                </div>
                                <div className='item orange-bg'>
                                    <div className='text'><span className='title'>Systems Integration:</span> Integrate seamlessly with CRMs, transactional systems, data sources and APIs</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-3.svg' alt='step 3' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-2.svg' alt='step 3' />
                                    <img className='arrow1' src='assets/img/all/downArrowRight.svg' alt='step 1' />
                                </div>
                                <div className='item blue-bg'>
                                    <div className='text'><span className='title'>Management & Reporting:</span> Monitor and review live experience to derive key insights to optimize experience</div>
                                    <img className='artwork' src='assets/img/all/services-section-4-artwork-4.svg' alt='step 4' />
                                    <img className='arrow' src='assets/img/all/services-section-4-arrow-3.svg' alt='step 4' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
