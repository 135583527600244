import React, { Component } from 'react'
import CommonButtons from '../common-buttons/CommonButtons';
import './AppPartners.scss';

export default class AppPartners extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section id="partners" className="partners-section">
                <div className="partners-header-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="partners-header">
                                <div className='left-section'>
                                    <h3>Partner with Us</h3>
                                    <p>Learn more about our Partner Program, including Partner Support and Incentives.</p>
                                    <CommonButtons></CommonButtons>
                                </div>
                                <img src="assets/img/all/partners-header-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partners-section-one-container">
                    <div className="container" data-aos="fade-up">
                        <div className='partners-section-one'>
                            <h3>Growth,</h3>
                            <h3>Shared Success and an</h3>
                            <span className='partners-section-one-header'>
                                <span className='web'>
                                    <img src="assets/img/all/partners-section-1-header-prefix.svg" className='process' alt="process" />
                                    Empowering Partnership
                                    <img src="assets/img/all/partners-section-1-header-suffix.svg" className='process' alt="process" />
                                </span>
                                <span className='mobile'>
                                    <img src="assets/img/all/partners-section-1-header-prefix.svg" className='process' alt="process" />
                                    <img src="assets/img/all/partners-section-1-header-suffix.svg" className='process' alt="process" />
                                    Empowering Partnership
                                </span>
                            </span>
                            <div className='strategic-partners'>
                                <span>Strategic Partners</span>
                                <div>
                                    <img src="assets/img/all/aws-logo.svg" className='process' alt="process" />
                                    <img src="assets/img/all/azure-logo.svg" className='process1' alt="process" />
                                </div>
                            </div>
                            <div className='power-your-solution'>
                                <h3>Power your <span className='text-highlighter-green display-linine-block'>Solutions</span></h3>
                                <p>Deliver differentiated value and beat your competition to market</p>
                            </div>
                            <img src="assets/img/all/partners-section-1-artwork.svg" className='partners-section-1-artwork' alt="process" />
                        </div>
                    </div>
                </div>
                <div className="partners-section-two-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="partners-section-two">
                                <div className='left-section'>
                                    <h3><span className='text-highlighter-green display-linine-block'>Scale</span> your CX services practice</h3>
                                    <p>Make crafting delightful brand experiences a repeatable process with more configuring and less coding</p>
                                </div>
                                <img src="assets/img/all/partners-section-2-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partners-section-three-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="partners-section-three">
                                <div className='left-section'>
                                    <h3>Contribute to the customer <span className='text-highlighter-green display-linine-block'>community</span></h3>
                                    <p>Showcase your expertise through proprietary integrations and UI kits.</p>
                                </div>
                                <img src="assets/img/all/partners-section-3-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
