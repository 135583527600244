import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import React, { Component } from 'react';
import './AppContactUs.scss';

interface IContactUsState {
    firstName: string,
    lastName: string,
    email: string,
    howCanIHelpYou: string,
    consent: boolean,
    landingFrom: string
}

export default class AppContactUs extends Component<{}, IContactUsState> {

    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            howCanIHelpYou: '',
            consent: true,
            landingFrom: 'Contact Us'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    getLearnMoreValue(str: string) {
        switch (str) {
            case 'home':
                return 'company';
            case 'services':
                return 'professional services';
            case 'solutions':
                return 'solutions';
            case 'partners':
                return 'partnership program';
            default:
                return '';
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const params = new URLSearchParams(window.location.search);
        let value = params.get('help');
        let src = params.get('src')?.toString() ?? '';
        if (value) {
            let txt = '';
            switch (value) {
                case 'learn':
                    txt = 'Learn more about ' + this.getLearnMoreValue(src);
                    break;
                case 'kt':
                    txt = 'Schedule a knowledge sharing session';
                    break;
                case 'demo':
                    txt = 'Schedule a demo session';
                    break;
                case 'collab':
                    txt = 'Explore collaboration opportunities';
                    break;
                case 'aws':
                    txt = 'AWS marketplace';
                    break;

            }
            this.setState({ howCanIHelpYou: txt, landingFrom: src });
        }
    }

    handleInputChange(event: { target: any; }) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;
        const newState = { [name]: value } as Pick<IContactUsState, keyof IContactUsState>;
        this.setState(newState);
    }

    async onSubmit(event: { preventDefault: () => void; target?: any; }) {
        event.preventDefault();
        const response = this.saveContact();

        toast.promise(
            response,
            {
                loading: 'Please wait...',
                success: <b>Thank you for reaching out to us! Our team will be in touch with you shortly.</b>,
                error: <b>Could not submit the details. Please try again later.</b>,
            }, {
            style: {
                borderRadius: '10px',
                background: '#fff',
                color: '#linear-gradient(44.84deg, #534AD8 -23.5%, #834C9D 107.38%)',
                width: '350px',
                height: '125px'
            },
        });
        if ((await response).status === 200) {
            this.setState({
                firstName: '',
                lastName: '',
                email: '',
                howCanIHelpYou: '',
                consent: true
            });
        }
        event.target.reset();
    }

    saveContact() {
        const payload = { ...this.state };
        let apiURL = process.env.REACT_APP_CONTACTUS_URL || "";
        let apiKey = process.env.REACT_APP_CONTACTUS_KEY;
        const headers = {
            "x-api-key": apiKey,
            "Content-Type": "application/json"
        };

        return axios
            .post(apiURL, payload, {
                headers: headers
            });
    }

    render() {
        return (
            <section id="contactUs" className='contact-us-section'>
                <div className='contact-us-header header-color '>
                    <div className='container' data-aos="fade-up">
                        <div className='title'>
                            {this.state.landingFrom === 'aws-connect' ? 'Let\'s Collaborate' : 'Contact Us'}
                        </div>
                        <p className='para'>
                            {this.state.landingFrom === 'aws-connect' 
                            ? 'Let\'s get on a quick call and discuss how we can help you design the future of service operations using AWS and our deep expertise in the field.' 
                            : 'We are thrilled to share our story and the results we are achieving with global industry leaders, and we are equally excited to hear your thoughts.'}
                        </p>
                    </div>
                </div>
                <div className='content container'>
                    <div className='contact-body' data-aos="fade-up">
                        <div className='general-inquiry'>
                            To reach out to us for general inquiries, including
                            partnership opportunities, speaking engagements, and
                            PR requests, kindly provide your contact information or
                            use the contact details provided below.
                        </div>
                        <div className='email header-color'>
                            Email Address
                        </div>
                        <div>
                            <a href='mailto:hello@rozie.ai' className='email-address'>hello@rozie.ai</a>
                        </div>
                        <div className='hidden'>
                            <div className='follow-us header-color'>
                                Follow Us
                            </div>
                            <div className='social-media'>
                                <div className='handle'>
                                    <img src="assets/img/all/contact-us/meta.svg" alt="meta" />
                                </div>
                                <div className='handle'>
                                    <img src="assets/img/all/contact-us/twitter.svg" alt="twitter" />
                                </div>
                                <div className='handle'>
                                    <img src="assets/img/all/contact-us/insta.svg" alt="insta" /></div>
                            </div>
                        </div>
                    </div>
                    <Toaster position="top-right" reverseOrder={false} />
                    <div className='contact-form container'>
                        <form onSubmit={this.onSubmit}>
                            <div className='name-row'>
                                <div className='input-item first-name'>
                                    <label htmlFor='firstName'>First Name</label>
                                    <input type='text' name='firstName' defaultValue={this.state.firstName} onChange={this.handleInputChange} required></input>
                                </div>
                                <div className='input-item last-name'>
                                    <label>Last Name</label>
                                    <input type='text' name='lastName' defaultValue={this.state.lastName} onChange={this.handleInputChange} required></input>
                                </div>
                            </div>
                            <div className='email-row'>
                                <div className='email-input'>
                                    <label>Email address</label>
                                    <input title="Please enter a valid business email only." pattern="^(?=.{1,256})(?=.{1,64}@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!@(gmail|yahoo|hotmail|outlook|aol|live|GMAIL|YAHOO|HOTMAIL|OUTLOOK|AOL|LIVE)\.com)$" type='email' name='email' defaultValue={this.state.email} onChange={this.handleInputChange} placeholder='Please enter your business email.' required></input>
                                </div>
                            </div>
                            <div className='comment-row'>
                                <div className='input-item'>
                                    <label>How can we help you?</label>
                                    <textarea id='comment' name='howCanIHelpYou' defaultValue={this.state.howCanIHelpYou} onChange={this.handleInputChange} required></textarea>
                                </div>
                            </div>
                            <div className='disclaimer'>
                                At RozieAI, we utilize the information you provide us to get in touch with you
                                regarding our relevant content, products, and services. If you wish to
                                unsubscribe, you may do so at any time. For further details on our privacy
                                practices, please refer to our Privacy Policy.
                                <br />
                            </div>
                            <div className='disclaimer'>
                                Additionally, you agree to allow RozieAI to collect the personal information you
                                have provided above.
                            </div>
                            <div className='agreement'>
                                <div className='consent-box'>
                                    <input type="checkbox" id="isAgree" name="consent" defaultChecked={this.state.consent} className='checkbox' onChange={this.handleInputChange} required />
                                    <div className='agreement-text'>By submitting this form, you consent to receive communications from RozieAI</div>
                                </div>
                                <input type='submit' className='submit-button' value='Submit Details'></input>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}


