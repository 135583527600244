import React, { Component } from 'react'
import CommonButtons from '../common-buttons/CommonButtons';
import './AppServices1.scss';


type MyProps = {};
type MyState = { selectedTab: string, isCareFullTextShown: boolean, isVirtualConcFullTextShown: boolean, isNoCodeFullTextShown: boolean, isDataAnalyticsFullTextShown: boolean, };
export default class AppServices1 extends Component<MyProps, MyState> {
    constructor(_props: any) {
        super(_props);
        this.state = {
            selectedTab: 'one',
            isCareFullTextShown: false,
            isVirtualConcFullTextShown: false,
            isNoCodeFullTextShown: false,
            isDataAnalyticsFullTextShown: false
        };
        this.btnClicked = this.btnClicked.bind(this);
        this.btnCollabrationClick = this.btnCollabrationClick.bind(this);
    }
    readLessHtml = "<div className='more-arrow'><img src='assets/img/services-1/less-arrow.svg' alt='Read less' /></div><div className='text'>Read less</div>";
    readMoreHtml = "<div className='more-arrow'><img src='assets/img/services-1/more-arrow.svg' alt='Read more' /></div><div className='text'>Read more</div>";
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    btnClicked(e: any) {
        let ctrlName = e.target.name;
        switch (ctrlName) {
            case 'tab-one':
                this.setState({ selectedTab: 'one' });
                break;
            case 'tab-two':
                this.setState({ selectedTab: 'two' });
                break;
            case 'tab-three':
                this.setState({ selectedTab: 'three' });
                break;
            case 'tab-four':
                this.setState({ selectedTab: 'four' });
                break;
            default:
                this.setState({ selectedTab: 'one' });
                break;
        }
    }

    handleReadMoreClick = (e: any) => {
        let ctrlName = e.currentTarget.id;
        console.log(ctrlName);
        switch (ctrlName) {
            case 'careReadMore':
                this.setState({ isCareFullTextShown: !this.state.isCareFullTextShown });
                break;
            case 'virtualConcReadMore':
                this.setState({ isVirtualConcFullTextShown: !this.state.isVirtualConcFullTextShown });
                break;
            case 'noCodeReadMore':
                this.setState({ isNoCodeFullTextShown: !this.state.isNoCodeFullTextShown });
                break;
            case 'dataAnalyticsReadMore':
                this.setState({ isDataAnalyticsFullTextShown: !this.state.isDataAnalyticsFullTextShown });
                break;
        }
    };

    btnCollabrationClick(e: any) {
        let ctrlName = e.target.name;
        let location = window.location.pathname.toString().substring(1);
        switch (ctrlName) {
            case 'collaboration':
                window.location.href = '/contactus?help=collab&src=' + location;
                break;
            case 'aws-marketplace':
                this.openInNewTab('https://aws.amazon.com/marketplace/seller-profile?id=74d95f87-d23c-4c0f-ac72-10612f496aa5');
                break;
        }
    }

    openInNewTab(url: string | URL | undefined) {
        window.open(url, "_blank", "noreferrer");
    };

    render() {
        return (
            <section id="services" className="services">
                <div className="services-header-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="services-header">
                                <div className='left-section'>
                                    <div className='header-text'>
                                        Design the Future of<br />
                                        Service Operations with<br />
                                        <span className='text-purple-gradient'>Amazon Connect</span> and <span className='text-purple-gradient'>RozieAI</span>
                                    </div>
                                    <CommonButtons></CommonButtons>
                                </div>
                                <img src="assets/img/services-1/header-artwork.svg" className='web-art' alt="Services Header" />
                                <img src="assets/img/services-1/header-artwork.svg" className='mobile-art' alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-section-one-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-one-aws'>
                            <div className='sections'>
                                <img src="assets/img/services-1/section-one-artwork-1.svg" alt="network-artwork" />
                                <div className='text-content'>
                                    Create a next-generation intelligent cloud-based contact center powered by Amazon Connect with the help of RozieAI - A trusted Amazon Connect service delivery partner and deliver exceptional customer experiences.
                                </div>
                            </div>
                            <div className='sections'>
                                <img src="assets/img/services-1/section-one-artwork-2.svg" alt="network-artwork" />
                                <div className='text-content'>Accelerate your digital transformation using AI and behavioral science to deliver smarter and kinder omnichannel customer experiences. </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-section-two-container">
                    <div className="container" data-aos="fade-up">
                        <div className='services-section-two'>
                            <div className='button-container'>
                                <button name='tab-one' onClick={this.btnClicked}
                                    className={this.state.selectedTab === "one" ? "btn active" : "btn"}>AWS Partner of The Year'22</button>
                                <button name='tab-two' onClick={this.btnClicked}
                                    className={this.state.selectedTab === "two" ? "btn active" : "btn"}>Tailored Solutions</button>
                                <button name='tab-three' onClick={this.btnClicked}
                                    className={this.state.selectedTab === "three" ? "btn active" : "btn"}>Quick Implementation</button>
                                <button name='tab-four' onClick={this.btnClicked}
                                    className={this.state.selectedTab === "four" ? "btn active" : "btn"}>Proven Methodology</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services-tabs-container">
                    <div className='services-tabs'>
                        {/* first tab */}
                        <div className={this.state.selectedTab === "one" ? "tab tab-one show" : "tab tab-one hide"}>
                            <div className='container'>
                                <div className="header-text">
                                    We are strategic partners with AWS and have vast experience with Amazon Connect, AWS Lambda, Amazon Alexa, Amazon Lex, Amazon Polly, Amazon Comprehend, Amazon S3, and Amazon SageMaker.  We are the winners of AWS Canada ProServe Partner of the Year 2022.
                                </div>
                            </div>
                            <div className='content'>
                                <div className="container" data-aos="fade-up" >
                                    <img src="assets/img/services-1/aws-partner-achievements.svg" className='main-img' alt="aws-partner" />
                                </div>
                            </div>
                        </div>

                        {/* second tab */}
                        <div className={this.state.selectedTab === "two" ? "tab tab-two show" : "tab tab-two hide"} >
                            <div className="container" data-aos="fade-up">
                                <div className='header-text'>
                                    We evaluate your current situation and assist you end-to-end in designing and implementing a tailored cloud-based intelligent contact center.
                                </div>
                                <div className='content'>
                                    <img src="assets/img/services-1/tailored-solutions-art.svg" alt="tailored-solutions" className='main-img' />
                                </div>
                            </div>
                        </div>


                        {/* third tab */}
                        <div className={this.state.selectedTab === "three" ? "tab tab-three show" : "tab tab-three hide"}>
                            <div className="container" data-aos="fade-up">
                                <div className='header-text'>
                                    We have pre-built modules that eliminate the need to start from scratch, enabling us to implement quickly.
                                </div>
                                <div className='content'>
                                    <img src="assets/img/services-1/quick-implementations.svg" alt="quick-implementation" className='main-img' />
                                </div>
                            </div>
                        </div>


                        {/* fourth tab */}
                        <div className={this.state.selectedTab === "four" ? "tab tab-four show" : "tab tab-four hide"}>
                            <div className="container" data-aos="fade-up">
                                <div className='header-text'>
                                    We use a proprietary approach that moves from strategic alignment to agile development in a short amount of time with exceptional customer satisfaction.
                                </div>
                                <div className='content'>
                                    <img src="assets/img/services-1/proven-methodology.svg" alt="proven-methodology" className='main-img' />
                                </div>
                            </div>
                        </div>

                        <div className='section-below-tabs'>

                            <div className="solutions-tab-one-section-two-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-two">
                                        <div className="left-section">
                                            <h3>Design and Implement Tailored Solutions Quickly with Our Pre-Built Modules to <span className='text-highlighter-green'>Deliver Best-in-Class</span> Customer Experiences
                                            </h3>
                                        </div>
                                        <div className="right-section">
                                            <p>We combine the power of Amazon Connect with our pre-built modules to create a next-generation intelligent digital cloud-based contact center tailored to your needs.</p>
                                            <p>This combination provides you with unified agent workspaces, intuitive self-service options, and advanced data analytics, that will help you deliver personalized and proactive customer experiences while maximizing ROI. Our pre-built modules also boast features that will help you with hassle-free administration of the contact center.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-three-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-three">
                                        <div className="left-section">
                                            <h3>Empower Your Agents with Rozie <span className='text-highlighter-green'>CARE</span>
                                            </h3>
                                            <p>RozieAI CARE provides a unified customer view and full-featured support for phone, email, and messaging, along with access to historical and real-time transcripts. </p>
                                            {
                                                this.state.isCareFullTextShown &&
                                                <p>AI-powered in-call training, script suggestions, and next-best action recommendations make it easy for your agents to deliver personalized care, empowering them to provide exceptional customer service. The simplified out-of-the-box administration tools simplify managing the contact center. </p>
                                            }

                                            <div id='careReadMore' onClick={this.handleReadMoreClick}>
                                                {
                                                    this.state.isCareFullTextShown ?
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readLessHtml }}></div>
                                                        )
                                                        :
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readMoreHtml }}></div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                        <div className="right-section">
                                            <img src="assets/img/services-1/empower-your-agents-with-care.svg" alt="empower-agents" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-four-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-four">
                                        <div className="left-section">
                                            <img src="assets/img/services-1/virtual-concierge.svg" alt="virtual-concierge" />
                                        </div>
                                        <div className="right-section">
                                            <h3>Enhance Customer Engagement with Rozie <span className='text-highlighter-green'>Virtual Concierge</span>
                                            </h3>
                                            <p>With Rozie Virtual Concierge, you can engage your customers in a unified conversational experience via digital and voice channels, including integrated voice response (IVR).</p>
                                            {
                                                this.state.isVirtualConcFullTextShown &&
                                                <p>The intelligent self-service engine provides seamless escalation to live agents and voice engagement configured on Amazon Lex, allowing customers to get the assistance they need quickly and easily. </p>
                                            }

                                            <div id='virtualConcReadMore' onClick={this.handleReadMoreClick}>
                                                {
                                                    this.state.isVirtualConcFullTextShown ?
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readLessHtml }}></div>
                                                        )
                                                        :
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readMoreHtml }}></div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-five-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-five">
                                        <div className="left-section">
                                            <h3>Design Customized Customer Experiences with Rozie's <span className='text-highlighter-green'>No Code</span> Experience Studio
                                            </h3>
                                            <p>Rozie Experience Studio provides a no-code design platform that enables you to create personalized interactions that incorporate AI-powered features like natural language understanding, sentiment analysis, and more.</p>
                                            {this.state.isNoCodeFullTextShown && <p>With this powerful tool, you can design and implement your own customized customer experiences without writing a single line of code. </p>}


                                            <div id='noCodeReadMore' onClick={this.handleReadMoreClick}>
                                                {
                                                    this.state.isNoCodeFullTextShown ?
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readLessHtml }}></div>
                                                        )
                                                        :
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readMoreHtml }}></div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                        <div className="right-section">
                                            <img src="assets/img/services-1/no-code.svg" alt="no-code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-six-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-six">
                                        <div className="left-section">
                                            <img src="assets/img/services-1/data-analytics.svg" alt="data-analytics" />
                                        </div>
                                        <div className="right-section">
                                            <h3>Optimize Performance and Improve Customer Experience with Rozie <span className='text-highlighter-green'>Data Analytics</span>
                                            </h3>
                                            <p>Rozie Data Analytics provides real-time and scheduled reporting that offers continuous awareness of contact center operations.</p>
                                            {
                                                this.state.isDataAnalyticsFullTextShown
                                                &&
                                                <p>With this advanced reporting dashboard, you can track agent performance, call content analysis, and more. Data capture and retention enable investigative analysis with Amazon Contact Lens, providing valuable insights that help you optimize performance and improve the customer experience. </p>
                                            }

                                            <div id='dataAnalyticsReadMore' onClick={this.handleReadMoreClick}>
                                                {
                                                    this.state.isDataAnalyticsFullTextShown ?
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readLessHtml }}></div>
                                                        )
                                                        :
                                                        (
                                                            <div className='read-more' dangerouslySetInnerHTML={{ __html: this.readMoreHtml }}></div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='button-container'>
                                        <button name='collaboration' className='collaboration' onClick={this.btnCollabrationClick}>Explore collaboration opportunities</button>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-seven-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-seven">
                                        <div className="left-section">
                                            <h3>
                                                How Do We Help You Design the Future of Service Operations Powered by <span className='text-highlighter-green'>Amazon Connect?</span>
                                            </h3>
                                            <p>Our methodology is focused on rapid prototyping, deployment, and iterative implementation. Using our proprietary approach, we guide you step-by-step and move from strategic alignment and design thinking workshops to execution and agile development planning quickly with exceptional customer satisfaction.</p>
                                        </div>
                                        <div className="right-section">
                                            <img src="assets/img/services-1/service-operations.svg" alt="service-operations" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions-tab-one-section-eight-container">
                                <div className="container" data-aos="fade-up">
                                    <div className="solutions-tab-one-section-eight">
                                        <div className="left-section">
                                            <img src="assets/img/services-1/rozie-rocket.svg" className='web-art' alt="rozie-rocket" />
                                            <img src="assets/img/services-1/rozie-rocket-sm.svg" className='mobile-art' alt="rozie-rocket" />
                                        </div>
                                        <div className="right-section">
                                            <h3>Ready to Design the Future of Service Operations?</h3>
                                            <p>Let us help you. We will assess your current situation and guide you step-by-step in designing the next-generation intelligent digital contact center powered by Amazon, so you can deliver smarter and kinder omnichannel customer experiences.</p>

                                            <div className='button-container'>
                                                <button name='collaboration' className='collaboration' onClick={this.btnCollabrationClick}>Collaboration Opportunities</button>
                                                <button name='aws-marketplace' className='aws-marketplace' onClick={this.btnCollabrationClick}>AWS Marketplace</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
