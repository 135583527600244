import { useEffect, useState } from 'react'
import './AnimatedText.scss'

const FADE_INTERVAL_MS = 800
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2
const WORDS_TO_ANIMATE = ['Memory', 'Empathy', 'Care', 'Compassion', 'Intuition', 'Continuity', 'Consistency', 'Meaningful Engagement', 'Rozie']

// type LoopProp = { loopCompleted: false | true }

export const AnimatedText = () => {
    // const [loopProp, setLoopProp] = useState<LoopProp>();
    const [wordOrder, setWordOrder] = useState(0)

    useEffect(() => {
        const wordTimeout = setInterval(() => {
            setWordOrder((prevWordOrder) => {
                if (prevWordOrder + 1 < WORDS_TO_ANIMATE.length) {
                    return (prevWordOrder + 1) % WORDS_TO_ANIMATE.length;
                } else {
                    // setLoopProp({loopCompleted: true});
                    return WORDS_TO_ANIMATE.length - 1;
                }
            });
        }, WORD_CHANGE_INTERVAL_MS);

        return () => {
            clearInterval(wordTimeout);
            // setLoopProp({loopCompleted: false});
        }
    }, [])

    return (
        <div className='animation-div'>
            <div className='pop-out-animation'>
                <span className='home-header-label'>{WORDS_TO_ANIMATE[wordOrder]}</span>
            </div>
        </div>
    )
}