import React, { Component } from 'react';
import './SocialMediaLinks.scss';

export default class SocialMediaLinks extends Component {
    render() {
        return (
            <div className='social-media-links-container'>
                <a className="nav-link scrollto" href="#contactus"><img src="assets/img/all/contact-us/meta.svg" alt="meta" /></a>
                <a className="nav-link scrollto" href="#contactus"><img src="assets/img/all/contact-us/twitter.svg" alt="twitter" /></a>
                <a className="nav-link scrollto" href="#contactus"><img src="assets/img/all/contact-us/insta.svg" alt="insta" /></a>
            </div>
        )
    }
}
