import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import SocialMediaLinks from '../social-media-links/SocialMediaLinks';
// import SocialMediaLinks from '../social-media-links/SocialMediaLinks';
import './AppHeader.scss';

const AppHeader = () => {
    const menuIconInput = useRef<HTMLInputElement>(null);
    //navbar scroll when active state
    const [navbar, setNavbar] = useState(false);
    const [partnerSubmenu, changePartnerSubmenuVisibility] = useState(false);
    useEffect(() => {
        changeBackground();
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    });

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const handleMenuItemClick = () => {
        if (menuIconInput.current) {
            menuIconInput.current.click();
            changePartnerSubmenuVisibility(false);
        }
    }

    const changeBackground = () => {
        if (window.scrollY >= 10 || splitLocation[1] !== 'home') {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    const btnClicked = () => {
        window.location.href = '/contactus?help=demo';
    }

    const handleClickForSubmenu = (e: any) => {
        let ctrlName = e.currentTarget.id;

        switch (ctrlName) {
            case 'partner-ddl':
                changePartnerSubmenuVisibility(!partnerSubmenu);
                break;

        }

    };

    return (
        <header id="header" className={!navbar ? "header fixed-top d-flex align-items-center transperant" : "header fixed-top d-flex align-items-center"}>
            <div className="container d-flex align-items-center justify-content-between">

                <div id="logo">
                    <a href="index.html"><img src="assets/img/all/rozie-logo.svg" alt="img" /></a>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li>
                            <Link to="/home" className={splitLocation[1] === "home" ? "nav-link scrollto active" : "nav-link scrollto"}>Home</Link>
                        </li>
                        <li>
                            <Link to="/solutions" className={splitLocation[1] === "solutions" ? "nav-link scrollto active" : "nav-link scrollto"}>Solutions</Link>
                        </li>
                        <li>
                            <Link to="/services" className={splitLocation[1] === "services" ? "nav-link scrollto active" : "nav-link scrollto"}>Services</Link>
                        </li>
                        <li className='dropdown'>
                            <Link to="/partners" className={splitLocation[1] === "partners" ? "nav-link scrollto active" : "nav-link scrollto"}>
                                Partners
                                <img src='assets/img/all/ddl-down-arrow.svg' alt='ddl'></img>

                            </Link>
                            <div className={splitLocation[1] === "aws-connect" ? "dropdown-content active" : "dropdown-content"}>
                                <Link to="/aws-connect">AWS</Link>
                            </div>
                        </li>
                        <li>
                            <Link to="/contactus" className={splitLocation[1] === "contactus" ? "nav-link scrollto active" : "nav-link scrollto"}>Contact</Link>
                        </li>

                        <li className='social-media-links hidden'>
                            <SocialMediaLinks></SocialMediaLinks>
                        </li>
                        <li className={splitLocation[1] === "home" ? "schedule-a-demo-btn hidden" : "schedule-a-demo-btn"}>
                            <button className='schedule-demo' name='scheduleDemo' onClick={btnClicked}>Schedule a Demo</button>
                        </li>
                    </ul>
                    <div className='mobile-navbar'>
                        <input type="checkbox" id="active" ref={menuIconInput} />
                        <label className="bi bi-list mobile-nav-toggle menu-btn" htmlFor='active'>
                            <span></span>
                        </label>
                        <label className="close" htmlFor='active'></label>
                        <div className="wrapper">
                            <ul>
                                <li>
                                    <Link to="/home" className={splitLocation[1] === "home" ? "nav-link scrollto active" : "nav-link scrollto"} onClick={handleMenuItemClick}>
                                        <label htmlFor='active'>Home</label>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/solutions" className={splitLocation[1] === "solutions" ? "nav-link scrollto active" : "nav-link scrollto"} onClick={handleMenuItemClick}>
                                        <label htmlFor='active'>Solutions</label>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services" className={splitLocation[1] === "services" ? "nav-link scrollto active" : "nav-link scrollto"} onClick={handleMenuItemClick}>
                                        <label htmlFor='active'>Services</label>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/partners" className={splitLocation[1] === "partners" ? "nav-link scrollto active" : "nav-link scrollto"} onClick={handleMenuItemClick}>
                                        <label htmlFor='active'>Partners</label>
                                    </Link>
                                    <img src='assets/img/all/ddl-down-arrow.svg' id='partner-ddl' className='mobile-dropdown-arrow' alt='ddl' onClick={handleClickForSubmenu}></img>
                                </li>
                                {
                                    partnerSubmenu ?
                                        (
                                            <li className={splitLocation[1] === "aws-connect" ? "dropdown-submenu active" : "dropdown-submenu"} onClick={handleMenuItemClick}>
                                                <Link to="/aws-connect">AWS</Link>
                                            </li>
                                        )
                                        :
                                        ''
                                }
                                <li>
                                    <Link to="/contactus" className={splitLocation[1] === "contactus" ? "nav-link scrollto active" : "nav-link scrollto"} onClick={handleMenuItemClick}>
                                        <label htmlFor='active'>Contact</label>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header >
    )
}

export default AppHeader;