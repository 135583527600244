import React from 'react'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import CommonButtons from '../common-buttons/CommonButtons';
import SocialMediaLinks from '../social-media-links/SocialMediaLinks';
import './AppFooter.scss';

const AppFooter = () => {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <footer className="footer">
            <div className={splitLocation[1] === 'home' || splitLocation[1] === 'contactus' ? 'footer-text hidden' : 'footer-text'}>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="footer-logo">
                                <p>RozieAI personalizes care at scale by harnessing the power of AI to deliver more human experiences</p>
                                <div className='home-button-container'>
                                    <CommonButtons></CommonButtons>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyrights'>
                <div className='container'>
                    <div className='text-section'>
                        <p>&copy; 2023 RozieAI</p>
                        <p><a href='/assets/docs/RozieAI+Privacy+Policy_web_version_v1.pdf' target={'_blank'}>Privacy Policy</a></p>
                        <p className='hidden'><Link to="https://www.rozie.ai/s/RozieAI-Privacy-Policy_web_version_v1.pdf" target={"_blank"}>Terms</Link></p>
                    </div>
                    <div className='hidden'>
                    <SocialMediaLinks></SocialMediaLinks>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default AppFooter;