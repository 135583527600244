import React, { Component } from 'react'
import CommonButtons from '../common-buttons/CommonButtons';
import './AppSolutions.scss';

export default class AppSolutions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section id="solutions" className="solutions">
                <div className="solutions-header-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-header">
                                <div className='left-section'>
                                    <p><span className='text-purple-gradient'>Rapidly scale</span> your sales,</p>
                                    <p>proactively engage your <span className='text-purple-gradient'>target audience,</span></p>
                                    <p><span className='text-purple-gradient'>automate</span> business processes and more.</p>
                                    <CommonButtons></CommonButtons>
                                </div>
                                <img src="assets/img/all/solution-header-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solutions-section-one-container">
                    <div className="container" data-aos="fade-up">
                        <div className='solutions-section-one'>
                            <div><span className='title'>No-Code.</span></div>
                            <div><span className='title'>Hyper-Personalized.</span></div>
                            <div><span className='title'>Omni Channel.</span></div>
                            <p>This is the Experience Innovation Platform Your Business Has Been Waiting For</p>
                        </div>
                    </div>
                </div>
                <div className="solutions-section-two-container">
                    <div className="container" data-aos="fade-up">
                        <div className='process-section'>
                            <h2>Accelerate</h2>
                            <h2>Time to Market of <span className='text-green'>AI-ENABLED</span> Experiences</h2>
                            <div className='image-ver-hor'>
                                <img src="assets/img/all/solutions-section-2-process.svg" className='process' alt="process" />
                            </div>
                            <div className='image-ver-hor1'>
                                <img src="assets/img/all/artworkVerticle.svg" className='process' alt="process" />
                            </div>

                            <p>Rapidly bring meaningful user experiences to life with Experience Studio. Craft experiences with best-in-class Language AI services and in-house custom ML models. Collaborative projects have never been this easy.</p>
                        </div>
                        <img src="assets/img/all/solutions-section-2-onboarding.svg" className='onboarding' alt="section-1" />
                    </div>
                </div>
                <div className="solutions-section-three-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-section-three">
                                <div className="connectWith">
                                    <h3>Connect With Customers Accross <span className='text-highlighter-green display-linine-block'>Channels.</span>
                                        <br />
                                        Have Just <span className='text-highlighter-green display-linine-block'>One Conversation.</span>
                                    </h3>
                                    <p>RozieAI’s Experience OS is an elastically scalable, context-aware, orchestration platform.</p>
                                    <p>Your digital and voice experiences can now remember each customer and intelligently personalize engagement. Unified omni-channel experiences made simple.</p>
                                </div>
                                <img src="assets/img/all/solutions-section-3-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solutions-section-four-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-section-four">
                                <h3>
                                    <span className='text-highlighter-green display-linine-block'>Illuminar: </span>
                                    &nbsp;Enterprise answer platform
                                </h3>
                                <p>Instantly give your customers and employees the answers they are searching for.
                                    Concise and accurate to unlock the value of your organization's knowledge. Productivity unlocked.</p>
                                {/* <img src="assets/img/all/solutions-section-4-illuminar.svg" alt="About" /> */}
                                <div className='image-illuminar-ver-hor'>
                                    <img src="assets/img/all/solutions-section-4-illuminar.svg" alt="process" />
                                </div>
                                <div className='image-illuminar-ver-hor1'>
                                    <img src="assets/img/all/illuminar-for-mobile.svg" alt="process" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solutions-section-five-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-section-five">
                                <h3>
                                    <span className='text-highlighter-green display-linine-block'>Concierge: </span>
                                    &nbsp;CX Automation
                                </h3>
                                <p>Add personalized service and support capacity. Unified brand voice enforced by design. Introduce consistent, channel optimized and cost-effective self-serve experiences.</p>

                            </div>
                        </div>
                    </div>
                    <img src="assets/img/all/solutions-section-5-illustration.svg" alt="About" />
                </div>
                <div className="solutions-section-six-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-section-six">
                                <h3>
                                    <span className='text-highlighter-blue display-linine-block'>Notifications: </span>
                                    &nbsp;Proactive Engagement
                                </h3>
                                <p>Garner attention through timely outreach based on business events. Pre-empt support requests by delivering awareness and offering advisory. Maximize attention value with contextually relevant marketing.</p>
                                <img src="assets/img/all/solutions-section-6-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solutions-section-seven-container">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-center">
                            <div className="solutions-section-seven">
                                <h3>
                                    <span className='text-highlighter-green display-linine-block'>CARE: </span>
                                    &nbsp;Augmented Intelligence for Live Support
                                </h3>
                                <p>
                                    Empower agents with real-time advisory and automation. A single-pane-of-glass for agents synthesizes the customer’s journey and recommends actions for efficient and delightful human support.
                                </p>
                                <img src="assets/img/all/solutions-section-7-artwork.svg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
