import React, { Component } from 'react';
import './CommonButtons.scss';

export default class CommonButtons extends Component {

    btnClicked(e: any) {
        let ctrlName = e.target.name;
        let location = window.location.pathname.toString().substring(1);
        switch (ctrlName) {
            case 'learnMore':
                window.location.href = '/contactus?help=learn&src=' + location;
                break;
            case 'scheduleDemo':
                window.location.href = '/contactus?help=demo&src=' + location;
                break;
        }
    }

    render() {
        return (
            <div className='home-button-container'>
                <button name='learnMore' className='learn-more' onClick={this.btnClicked}>Learn More</button>
                <button name='scheduleDemo' className='schedule-demo' onClick={this.btnClicked}>Schedule a Demo</button>
            </div>
        )
    }
}
