import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { AnimatedText } from '../animated-text/AnimatedText';
import CommonButtons from '../common-buttons/CommonButtons';
import './AppMain.scss';

export default class AppMain extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="all-sections">
                <section id="home">
                    <div className="hero-container" data-aos="fade-in">
                        <h2>Imagine AI powered Experiences with</h2>
                        <AnimatedText></AnimatedText>
                        <div className='caption-text'><p>RozieAI personalizes care at scale by harnessing the power of AI
                            to deliver more human experiences</p>
                        </div>
                        <CommonButtons></CommonButtons>
                        <section className='feature-container'>
                            <div className='feature'>
                                <Link to="/solutions">
                                    <div className='solutions-tile'>
                                        <span className='text'>Learn about our <br/>
                                            <span className='text-highlighter-purple'>AI platform</span>
                                            &nbsp;and solutions to rapidly design and launch innovative experiences
                                        </span>
                                        <img src="assets/img/all/home-tile-2-artwork.svg" alt="img" />
                                    </div>
                                </Link>
                            </div>
                            <div className='feature'>
                                <Link to="/services">
                                    <div className='services-tile'>
                                        <span className='text'>Accelerate your<br/>
                                            <span className='text-highlighter-green'>digital transformation</span>
                                            &nbsp;and build a unified brand experience
                                        </span>
                                        <img src="assets/img/all/home-tile-1-artwork.svg" alt="img" />
                                    </div>
                                </Link>
                            </div>
                            <div className='feature'>
                                <Link to="/partners">
                                    <div className='partners-tile'>
                                        <span className='text'>
                                            <span className='text-highlighter-orange'>Partner</span>
                                            &nbsp;with us to help your clients maximize the lifetime value of their customers
                                        </span>
                                        <img src="assets/img/all/partners-header-artwork.svg" alt="img" />
                                    </div>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
            </section>
        )
    }
}
