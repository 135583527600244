import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'
import './App.scss';
import AppHeader from './header/AppHeader';
import AppFooter from './footer/AppFooter';
import AppMain from './main/AppMain';
import AppSolutions from './solutions/AppSolutions';
import AppPartners from './partners/AppPartners';
import AppContactUs from './contactus/AppContactUs';
import AppServices from './Services/AppServices';
import AppServices1 from './Services-1/AppServices1';

function App() {
  return (
    <div>
      <AppHeader></AppHeader>
      <Routes>
        <Route path='/home' element={< AppMain />}></Route>
        <Route path='/solutions' element={< AppSolutions />}></Route>
        <Route path='/services' element={< AppServices />}></Route>
        <Route path='/partners' element={< AppPartners />}></Route>
        <Route path='/contactus' element={<AppContactUs />}></Route>
        <Route path='/aws-connect' element={<AppServices1 />}></Route>
        <Route
          path="*"
          element={
            <Navigate to="/home" />
          }
        />
      </Routes>
      <AppFooter></AppFooter>
    </div>
  );
}

export default App;
